import { useEffect, useState } from "react";
import L from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import { CircleMarker, Marker, Popup, GeoJSON } from "react-leaflet";

// Actions
import { disableFilter, enableFilter } from "../../actions/live-congestion/ListCongestionListActions";

// Constants
import { CHENNAI_LAT_LONG, STATUS_COLOR } from "../../constants/GeneralConstants";
import { GEO_JSON_BOUNDRIES_DATA } from "../../constants/GeoJSONBoundries";
import { FILTERS, FILTERS_DESCRIPTION } from "../../constants/JunctionConstants";

// Utils
import getColorCode from "../../utils/TrafficUtils";

// Components
import LLMap from "../../components/map/LLMap";

// Icons
import camera from "../../assets/images/icons/camera.png";
import chalanMachine from "../../assets/images/icons/challanmachine.png";
import signalRemote from "../../assets/images/icons/signalremote.png";
import breathAnalyser from "../../assets/images/icons/breathanalyser.png";

// Page Components
// ----------------------------------------------------------------------------

function SignalMarkers({ congestionList = [] }) {
  return (
    <>
      {congestionList?.map((congestion, index) => {
        const { junctionName, delayInSecs, location } = congestion;

        const { latitude, longitude } = location;

        // To get color code
        const { radius, colorCode } = getColorCode({ delayInSecs });

        // Path options
        const pathOptions = {
          fillColor: STATUS_COLOR[colorCode],
          fillOpacity: 1,
          radius,
          className: "marker-traffic-state",
          stroke: true,
          color: "#333",
          weight: 2,
        };

        return (
          <CircleMarker key={index} center={[latitude, longitude]} pathOptions={pathOptions}>
            <Popup>{junctionName}</Popup>
          </CircleMarker>
        );
      })}
    </>
  );
}

function OfficerMarkers({ activeOfficerJunctions = [], junctionStatesMap = {} }) {
  return (
    <>
      {activeOfficerJunctions.map((j, index) => {
        // Junction
        const { id: junctionId, name: junctionName, location } = j;
        const { latitude, longitude } = location;

        // JunctionState :: Checked in Officers
        const junctionState = junctionStatesMap[junctionId] || {};
        const { policeMap = {} } = junctionState;

        const officerList = Object.values(policeMap) || [];

        // Marker Icon
        const officerIcon = L.divIcon({
          html: "",
          iconSize: [20, 20],
          className: "map-icon circle white map-icon-user",
        });

        return (
          <Marker key={index} position={[latitude, longitude]} icon={officerIcon}>
            <Popup>
              <>
                <h6>{junctionName}</h6>

                {officerList.map((p) => {
                  return (
                    <p>
                      {p.name} | {p.phoneNo}
                    </p>
                  );
                })}
              </>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
}

// Helper Functions
function getDeviceIcon(renderIcon, icon) {
  if (!renderIcon) {
    return "";
  }

  return L.icon({
    iconUrl: icon,
    iconSize: [20, 20],
    className: "map-icon-devices-background",
  });
}

function DeviceMarkers({ activeOfficerJunctions = [], junctionStatesMap = {} }) {
  const filtersMap = useSelector((state) => state.liveCongestionList.filtersMap);

  function getDeviceInfo(junctionState) {
    const {
      hasBodyCamera = false,
      hasBreathAnalyser = false,
      hasChallanMachine = false,
      hasSignalRemote = false,
    } = junctionState || {};

    const bodyCameraIcon = getDeviceIcon(hasBodyCamera, camera);
    const breathAnalyserIcon = getDeviceIcon(hasBreathAnalyser, breathAnalyser);
    const challanMachineIcon = getDeviceIcon(hasChallanMachine, chalanMachine);
    const signalRemoteIcon = getDeviceIcon(hasSignalRemote, signalRemote);

    return { bodyCameraIcon, breathAnalyserIcon, challanMachineIcon, signalRemoteIcon };
  }

  return (
    <>
      {activeOfficerJunctions.map((j, index) => {
        const { id: junctionId, location } = j;
        const { latitude, longitude } = location;

        const junctionState = junctionStatesMap[junctionId];
        const { bodyCameraIcon, breathAnalyserIcon, challanMachineIcon, signalRemoteIcon } =
          getDeviceInfo(junctionState);

        return (
          <div key={`${index}_device_markers`}>
            {bodyCameraIcon && filtersMap[FILTERS.BODY_CAMERA] && (
              <Marker position={[latitude, longitude]} icon={bodyCameraIcon}></Marker>
            )}
            {breathAnalyserIcon && filtersMap[FILTERS.BREATH_ANALYSER] && (
              <Marker position={[latitude, longitude]} icon={breathAnalyserIcon}></Marker>
            )}
            {challanMachineIcon && filtersMap[FILTERS.CHALAN_MACHINE] && (
              <Marker position={[latitude, longitude]} icon={challanMachineIcon}></Marker>
            )}
            {signalRemoteIcon && filtersMap[FILTERS.SIGNAL_REMOTE] && (
              <Marker position={[latitude, longitude]} icon={signalRemoteIcon}></Marker>
            )}
          </div>
        );
      })}
    </>
  );
}

function RegionBoundary({ geoJSONData }) {
  const showBoundary = useSelector((state) => state.liveCongestionList.filtersMap[FILTERS.REGION_BOUNDARY]);
  const isGeoJSONDataPresent = Object.keys(GEO_JSON_BOUNDRIES_DATA).length !== 0;

  if (!isGeoJSONDataPresent || !showBoundary) {
    return null;
  }

  return <GeoJSON data={geoJSONData} />;
}

function ToggleItem({ filterType, onClick }) {
  const filterEnabled = useSelector((state) => state.liveCongestionList.filtersMap[filterType]);

  // Icon Classname
  const iconClassName = filterEnabled ? "fa-toggle-on text-success" : "fa-toggle-off text";

  return (
    <div onClick={() => onClick(filterType, filterEnabled)}>
      <i className={`fa-solid fa-xl ${iconClassName}`}></i>
      <span className="ms-1">{FILTERS_DESCRIPTION[filterType]}</span>
    </div>
  );
}

function ToggleFilters({ isGeoJSONDataPresent = false }) {
  const dispatch = useDispatch();

  // If data is not present, boundry toggle button is not shown
  if (!isGeoJSONDataPresent) {
    return null;
  }

  function onClickToggleItem(filterType, filterEnabled) {
    if (filterEnabled) {
      dispatch(disableFilter({ filterType }));
      return;
    }

    dispatch(enableFilter({ filterType }));
  }

  return (
    <div className={`toggle-filters-container m-4 bg-white p-2 rounded-1`}>
      <ToggleItem onClick={onClickToggleItem} filterType={FILTERS.REGION_BOUNDARY} />
      <div className="mt-2">
        Devices
        <ToggleItem onClick={onClickToggleItem} filterType={FILTERS.BODY_CAMERA} />
        <ToggleItem onClick={onClickToggleItem} filterType={FILTERS.BREATH_ANALYSER} />
        <ToggleItem onClick={onClickToggleItem} filterType={FILTERS.CHALAN_MACHINE} />
        <ToggleItem onClick={onClickToggleItem} filterType={FILTERS.SIGNAL_REMOTE} />
      </div>
    </div>
  );
}

// Helper Function
function getActiveOfficersJunctionList(junctionList = [], junctionStatesMap = {}) {
  return junctionList.filter((junction) => {
    const { id: junctionId, location = {} } = junction;
    const jState = junctionStatesMap[junctionId] || {};

    // Check
    const isValidJunction = Object.keys(location).length > 0;
    const isOfficerCheckedIn = jState.policeIds?.length > 0;
    return isValidJunction && isOfficerCheckedIn;
  });
}

/**
 * Congestion Map Section ==> This Component holds all the information related to congestion
 * displayed on the Map
 */
export default function CongestionMapView({
  junctionList = [],
  junctionStatesMap = {},
  congestionList = [],
  freeFlowDelay = true,
  showActiveOfficers = false,
  showActiveDevices = false,
}) {
  const [activeOfficerJunctions, setActiveOfficerJunctions] = useState([]);

  useEffect(() => {
    const activeOfficerJunctionArray = getActiveOfficersJunctionList(junctionList, junctionStatesMap);

    setActiveOfficerJunctions(activeOfficerJunctionArray);
  }, [junctionList, junctionStatesMap]);

  const isGeoJSONDataPresent = Object.keys(GEO_JSON_BOUNDRIES_DATA).length !== 0;

  return (
    <div className="col-8">
      <div className="map-container">
        <LLMap center={CHENNAI_LAT_LONG}>
          {/** Boundary */}
          <RegionBoundary geoJSONData={GEO_JSON_BOUNDRIES_DATA} />

          {/** Filters */}
          <ToggleFilters isGeoJSONDataPresent={isGeoJSONDataPresent} />

          {/** Signal Markers */}
          <SignalMarkers
            junctionList={junctionList}
            junctionStatesMap={junctionStatesMap}
            congestionList={congestionList}
            freeFlowDelay={freeFlowDelay}
          />

          {/** Active Officers */}
          {showActiveOfficers && ( //
            <OfficerMarkers activeOfficerJunctions={activeOfficerJunctions} junctionStatesMap={junctionStatesMap} />
          )}

          {/** Active Devices */}
          {showActiveDevices && ( //
            <DeviceMarkers activeOfficerJunctions={activeOfficerJunctions} junctionStatesMap={junctionStatesMap} />
          )}
        </LLMap>
      </div>
    </div>
  );
}
