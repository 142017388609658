import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Business Components
import CongestionMapView from "../../business-components/congestion/CongestionMapView";
import CongestionSearchForm from "../../business-components/congestion/CongestionSearchForm";
import CongestionTable from "../../business-components/congestion/CongestionTable";

import JunctionStateTable from "./JunctionStateTable";

// Page Constants
const REFRESH_INTERVAL = 60;

/**
 * Get the current Hour of the Day.
 * For 11 and 21 -> make it 10 and 20.
 */
function getHourNow() {
  const nowDate = new Date();
  let nowHours = nowDate.getHours();
  if (nowHours === 11 || nowHours === 21) {
    nowHours = nowHours - 1;
  }

  return nowHours;
}

/**
 * Live Monitor Page
 */
export default function LiveMonitorPage() {
  // Page State
  const [refreshSecs, setRefreshSecs] = useState(1);

  const [freeFlowDelay, setFreeFlowDelay] = useState(true); // Delay is measured against Free-flow Traffic
  const [showJunctionState, setShowJunctionState] = useState(true); // Set to true to show JunctionState; false for Congestion list

  // Selector State
  const liveCongestionList = useSelector((state) => state.liveCongestionList.liveCongestionList);
  const liveCongestionListLoading = useSelector((state) => state.liveCongestionList.liveCongestionListLoading);

  const junctionsMap = useSelector((state) => state.liveCongestionList.junctionsMap);
  const junctionList = junctionsMap ? Object.values(junctionsMap) : [];
  const junctionStatesMap = useSelector((state) => state.liveCongestionList.junctionStatesMap);

  useEffect(() => {
    if (!refreshSecs) {
      return;
    }
    const refreshIntervalId = setInterval(() => {
      const newRefreshSecs = refreshSecs === 1 ? REFRESH_INTERVAL : refreshSecs - 1;
      setRefreshSecs(newRefreshSecs);
    }, 1000);

    return () => clearInterval(refreshIntervalId);
  }, [refreshSecs]);

  // Congestion List (with updated delayInSecs value)
  const congestionList = React.useMemo(() => {
    const hourOfDay = getHourNow();
    const cList = liveCongestionList
      .map((c) => {
        // JunctionState
        const junctionId = c.junctionId;
        const junctionState = junctionStatesMap[junctionId];
        if (freeFlowDelay || !junctionState) {
          return c; // return as is - as JunctionState data is not present
        }

        // Usual delay
        const usualDelayInSecs = junctionState?.hourlyAvg["_" + hourOfDay] || 0;
        const liveDelayInSecs = c.delayInSecs;
        const newDelayInSecs = liveDelayInSecs > usualDelayInSecs ? liveDelayInSecs - usualDelayInSecs : 0;

        // Update delay
        const cUpdated = {
          ...c,
          delayInSecs: newDelayInSecs,
        };

        return cUpdated;
      })
      .filter((c) => {
        // pick only the delays with +ve delay value
        return c.delayInSecs > 0;
      });

    return cList;
  }, [liveCongestionList, junctionStatesMap, freeFlowDelay]);

  // Sort Congestion List (descending order of delay)
  congestionList.sort((c1, c2) => c2.delayInSecs - c1.delayInSecs);

  // Field Officers
  const activeJunctionsList = React.useMemo(() => {
    const junctionStateList = Object.values(junctionStatesMap) || [];
    const activeJunctionsList = junctionStateList.filter((jState) => {
      const { junctionId = "", policeIds = [] } = jState;
      return junctionId && policeIds.length > 0;
    });

    return activeJunctionsList;
  }, [junctionStatesMap]);

  // Results Length
  const congestionCount = congestionList.length || 0;
  const fieldOfficerCount = activeJunctionsList.length || 0;

  //
  return (
    <div className="row gx-0">
      {/* Congestion Section*/}
      <div className="col-4 p-3 overflow-auto content">
        {/** Search Form */}
        <CongestionSearchForm
          isLive={true}
          refreshSecs={refreshSecs}
          freeFlowDelay={freeFlowDelay}
          setFreeFlowDelay={setFreeFlowDelay}
        />

        <hr />

        {/** Header */}
        <h5 className="d-flex justify-content-between">
          <div
            class="btn-group"
            role="group"
            aria-label="Basic outlined example"
            onClick={() => setShowJunctionState(!showJunctionState)}
          >
            <button type="button" class={`btn btn-outline-primary ${showJunctionState ? "active" : ""}`}>
              Field Officers ({fieldOfficerCount})
            </button>
            <button type="button" class={`btn btn-outline-primary ${showJunctionState ? "" : "active"}`}>
              Traffic Congestions ({congestionCount})
            </button>
          </div>
          <small>Auto refresh in {refreshSecs} secs</small>
        </h5>

        {/**  */}
        {showJunctionState && (
          // Show Junctions with check-ins
          <JunctionStateTable
            isLive={true}
            congestionList={congestionList}
            activeJunctionsList={activeJunctionsList}
            junctionsMap={junctionsMap}
          />
        )}

        {/**  */}
        {!showJunctionState && (
          // Show Congestions
          <CongestionTable
            isLive={true}
            freeFlowDelay={freeFlowDelay}
            junctionStatesMap={junctionStatesMap}
            congestionList={congestionList}
            congestionListLoading={liveCongestionListLoading}
          />
        )}
      </div>

      {/* Live Monitor  map view  */}
      <CongestionMapView
        junctionList={junctionList}
        junctionStatesMap={junctionStatesMap}
        congestionList={congestionList}
        freeFlowDelay={freeFlowDelay}
        showActiveOfficers={true}
        showActiveDevices={true}
      />
    </div>
  );
}
