import { Link } from "react-router-dom";

// Constants

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectUrls";

// Components
import TableDataNotFound from "../../components/table/TableDataNotFound";

// Utils

// Page Components
// ----------------------------------------------------------------------------

function JunctionNameCell({ junction = {} }) {
  const { id = "", name, signalNo } = junction;

  // Redirect
  const url = RedirectTo.signalLiveMonitorPageUrl.replace(":congestionId", id);

  //
  return (
    <td className="" title={name}>
      <Link to={url} className="d-block truncate">
        {name}
      </Link>
      <small key={id} className="mb-0 text-muted">
        {signalNo}
      </small>
    </td>
  );
}

function OfficersInfoCell({ policeList }) {
  //
  return (
    <td className="">
      {policeList.map((p) => {
        const { id, name, phoneNo } = p;
        return (
          <p key={id} className="mb-0 text-muted">
            {name} ({phoneNo})
          </p>
        );
      })}
    </td>
  );
}

function JunctionStateTableBody({ isLive, junctionsMap, activeJunctionsList }) {
  //If there is no data
  if (activeJunctionsList.length === 0) {
    const message = isLive ? "No Active officers data present" : "No Active officers history data ";
    return <TableDataNotFound colSpan={3} message={message} />;
  }

  //
  return (
    <>
      {activeJunctionsList.map((juncState, index) => {
        const {
          junctionId,
          policeMap = {},
          hasSignalRemote = false,
          hasChallanMachine = false,
          hasBreathAnalyser = false,
          hasBodyCamera = false,
        } = juncState;
        const policeList = Object.values(policeMap) || [];
        const junction = junctionsMap[junctionId] || {};

        //
        return (
          <tr key={index}>
            {/** Junctions */}
            <JunctionNameCell junction={junction} />

            {/* Officers */}
            <OfficersInfoCell policeList={policeList} />

            {/* Devices */}
            <td>
              <div className="d-flex justify-content-center">
                {hasSignalRemote && (
                  <span className="mx-1" title="Signal Remote">
                    <i className="fa fa-wifi" />
                  </span>
                )}

                {hasChallanMachine && (
                  <span className="mx-1" title="Challan Machine">
                    <i className="fa fa-receipt" />
                  </span>
                )}

                {hasBreathAnalyser && (
                  <span className="mx-1" title="Breath Analyser">
                    <i className="fa fa-wine-bottle" />
                  </span>
                )}

                {hasBodyCamera && (
                  <span className="mx-1" title="Body Camera">
                    <i className="fa fa-camera" />
                  </span>
                )}
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
}

/**
 * Congestion section
 */
export default function JunctionStateTable({
  isLive = true,
  congestionList,
  activeJunctionsList = [],
  junctionsMap = {},
}) {
  //
  return (
    <div className="table-responsive">
      <table className="table table-compact mt-3">
        <thead>
          <tr>
            <th className="">
              Signal Name <span className="sub-text">Signal No</span>
            </th>
            <th className="">
              Active Officer <span className="sub-text"> Contact </span>
            </th>
            <th className="">
              Devices <span className="sub-text"> &nbsp; </span>
            </th>
          </tr>
        </thead>

        <tbody>
          {/* Congestion Table Body */}
          <JunctionStateTableBody
            isLive={isLive}
            junctionsMap={junctionsMap}
            activeJunctionsList={activeJunctionsList}
          />
        </tbody>
      </table>
    </div>
  );
}
